<template>
  <div class="d-flex flex-column">
    <v-progress-circular
      rotate="90"
      size="100"
      width="12"
      :value="value"
      color="primary"
      class="mt-2"
    >
      <strong>{{ value.toFixed(2) }}%</strong>
    </v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "ProgressField",
  props: ["value"],
};
</script>

<style scoped>
</style>